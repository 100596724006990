<template>
<div class="out-cont">
  <div class="in-cont" :style="bgClass">
    <div class="show-img" v-if="imgUrl">
      <img v-show="BinaryImg" class="show" :src="BinaryImg" alt="">
      <img v-show="! BinaryImg" class="show" :src="imgUrl" alt="">
      <div class="del" @click="delUrl('')">
        <img src="@/assets/img/del.png" alt="">
      </div>
    </div>
    <div class="add" v-show="! imgUrl" @click="upOpen">
      <div class="icon">
        <img src="@/assets/img/add.png" alt="">
      </div>
      <div class="text">点击上传</div>
    </div>
  </div>
  <div class="label-cont">
    {{this.label}} <span class="red" v-if="this.master">*</span>
    <div v-if="label2">{{label2}}</div>
  </div>
  <!-- <input type="file" @change="upAjax($event)" accept="image/png,image/jpeg,image/jpg,image/bmp" ref="file" style="display:none;"> -->
  <input type="file" @change="upAjax($event)" accept="image/*" ref="file" style="display:none;">

  
</div>
</template>

<script>
import ImageCompressor from 'image-compressor.js'

export default {
  name: 'UpFile',
  props: ['bg', 'master', 'label', 'img', 'label2', 'accept'],
  data () {
    return {
      bgClass: {'background-image': 'url('+this.bg+')'},
      imgUrl: this.img,
      path: window.sessionStorage.getItem('imgPath'),
      BinaryImg: '', // 二进制图片
    }
  },
  watch: {
    img () {
      if(this.img){
        this.imgUrl = this.path + this.img;
      }else{
        this.imgUrl = '';
      }
    }
  },
  methods: {
    /**
     * 上传触发
     */
    upOpen () {
      this.$refs.file.click()
    },
    /**
     * 图片转BASE64
     */
    toView (file) {
      const vm = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) { 
        vm.BinaryImg = this.result; 
      }
    },
    /**
     * 上传服务
     */
    upAjax (e) {
      const vm = this;
      let file = e.target.files[0];

      if(this.accept && this.accept.length > 0){
        let imgType = file.type;
        if(this.accept.indexOf(imgType.toLowerCase()) < 0){
          vm.$createToast({
            txt: '请上传' + this.accept.join(',') + '格式图片',
            type: 'warn'
          }).show()
          return false;
        }
      }

      new ImageCompressor(file, {
        quality: .6,
        success(result) {      
          const formData = new FormData();
          formData.append('file', result, result.name);
    
          vm.$axios({
            method: 'post',
            baseURL: vm.$init.apis.default,
            url: "/apply/file/upload", // 
            data: formData,
            timeout: 600000,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function(response) {
            if (response.data.header.code == "A1000") {
              // vm.imgUrl = window.URL.createObjectURL(file);
              vm.path = response.data.body.path;
              vm.upFile(response.data.body.fileName);
              vm.toView(result)
            } else {
              vm.$createToast({
                txt: response.data.header.msg,
                type: 'warn'
              }).show()
            }
          }).catch(function(err) {
            vm.$createToast({
              txt: '网络错误',
              type: 'error'
            }).show()
          });
        },
        error(e) {

        },
      });
    },
    /**
     * 删除图片地址
     */
    delUrl () {
      this.imgUrl = '';
      this.$refs.file.value = null;
      this.upFile('')
    },
    /**
     * 更新图片地址
     */
    upFile (url) {
      this.$emit('updata', url);
    }
  }
}
</script>

<style lang="scss" scoped>
.out-cont{
  width: 200px;
}
.in-cont{
  height: 180px;
  width: 200px;
  box-sizing: border-box;
  position: relative;
  background-size: cover;
}
.label-cont{
  text-align: center;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  padding: 10px 0;
}
.red{
  color: #E74857;
}
.add{
  height: 180px;
  width: 100%;
  .icon{
    padding: 48px 0 43px 0;
    img{
      display: block;
      height: 44px;
      width: 44px;
      margin: 0 auto;
    }
  }
  .text{
    background: #999999;
    border-radius: 0 0 14px 14px;
    color: #fff;
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
  }
}
.show-img{
  border-radius: 14px;
  box-sizing: border-box;
  height: 180px;
  border: 1px solid #999999;
  overflow: hidden;
  position: relative;
  .show{
    display: block;
    width: 100%;
    height: 100%;
  }
  .del{
    position: absolute;
    z-index: 3;
    right: 14px;
    top: 14px;
    img{
      display: block;
      height: 30px;
      width: 30px;
    }
  }
}
</style>