<template>
  <div class="form-cont">
    <div class="group-name">
      <div class="group-line">
        <div class="group-line-in"></div>
      </div>
      <div class="group-title">损坏部位的照片</div>
    </div>
    <div class="flex-cont">
      <UpFile 
        @updata="upFile($event, 'damageFront')"
        :img="form.damageFront"
        :bg="bg.sq1"
        label="设备正面照片" 
        label2="(显示IMEI号)" 
        :master="true"></UpFile>

      <UpFile 
        @updata="upFile($event, 'damageReverse')"
        :img="form.damageReverse"
        :bg="bg.sq2"
        label="设备反面照片"
        :master="true"></UpFile>

      <UpFile 
        @updata="upFile($event, 'damageSide')"
        :img="form.damageSide"
        :bg="bg.sq3"
        label="设备侧面照1"
        :master="true"></UpFile>
    </div>
    <div class="flex-cont">
      <UpFile 
        @updata="upFile($event, 'damageSideTwo')"
        :img="form.damageSideTwo"
        :bg="bg.sq4"
        label="设备侧面照2"
        :master="false"></UpFile>
    </div>


    <div class="group-name">
      <div class="group-line">
        <div class="group-line-in"></div>
      </div>
      <div class="group-title">身份证照片</div>
    </div>
    <div class="flex-cont">
      <UpFile 
        @updata="upFile($event, 'identityHand')"
        :img="form.identityHand"
        :bg="bg.sq5"
        label="上传手持身份证"
        :master="true"></UpFile>

      <UpFile 
        @updata="upFile($event, 'identityFront')"
        :img="form.identityFront"
        :accept="['image/png', 'image/jpg', 'image/jpeg', 'image/bmp']"
        :bg="bg.sq6"
        label="上传身份证正面"
        :master="true"></UpFile>

      <UpFile 
        @updata="upFile($event, 'identityReverse')"
        :img="form.identityReverse"
        :accept="['image/png', 'image/jpg', 'image/jpeg', 'image/bmp']"
        :bg="bg.sq7"
        label="上传身份证反面"
        :master="true"></UpFile>
    </div>

    <div class="box shadow">
      <div class="form-group line">
        <div class="label">姓名：</div>
        <div class="cont">
          <input type="text" :value="aiVerdict.name" readonly placeholder="请上传身份证正面照片">
        </div>
      </div>
      <div class="form-group line">
        <div class="label">身份证号：</div>
        <div class="cont">
          <input type="text" :value="aiVerdict.idcard" readonly placeholder="请上传身份证正面照片">
        </div>
      </div>
      <div class="form-group line">
        <div class="label">有效期：</div>
        <div class="cont">
          <input type="text" :value="aiVerdict.period" readonly placeholder="请上传身份证反面照片">
        </div>
      </div>
    </div>


    <div class="group-name">
      <div class="group-line">
        <div class="group-line-in"></div>
      </div>
      <div class="group-title">购买凭证</div>
    </div>
    <div class="group-label">无法开机设备需要上传购买凭证，包括但不限于购机发票、收据</div>
    <div class="flex-cont">
      <UpFile 
        @updata="upFile($event, 'voucher')"
        :img="form.voucher"
        :bg="bg.sq8"
        label="购买凭证"
        :master="false"></UpFile>
    </div>



    <div class="btns" v-if="type != 'view'">
      <cube-button class="btn" @click="saveData">提交</cube-button>
    </div>


    <div class="modo" v-if="show">
      <div class="modo-in">
        <div class="modo-icon">
          <img src="@/assets/img/success.png" alt="">
        </div>
        <div class="modo-name">您的申请已提交</div>
        <div class="modo-title">
          客服人员将在24小时内与您登记的手机号进行联系，请注意接听电话。
          <p>
            客服电话：<span>400-890-0001</span>
          </p>
        </div>
        <div class="modo-btn" @click="goNext">确定</div>
      </div>
    </div>



  </div>
</template>

<script>
import sq1 from '@/assets/img/sq1.png'
import sq2 from '@/assets/img/sq2.png'
import sq3 from '@/assets/img/sq3.png'
import sq4 from '@/assets/img/sq4.png'
import sq5 from '@/assets/img/sq5.png'
import sq6 from '@/assets/img/sq6.png'
import sq7 from '@/assets/img/sq7.png'
import sq8 from '@/assets/img/sq8.png'

import UpFile from '@/components/UpFile.vue'
export default {
  name: 'dataFile',
  components: {
    UpFile
  },
  data () {
    return {
      show: false,
      type: this.$route.query.type,
      // 背景图
      bg: {
        sq1,
        sq2,
        sq3,
        sq4,
        sq5,
        sq6,
        sq7,
        sq8,
      },
      // 图片
      form: {
        "id": this.$route.params.id, // 保单ID

        "brand": '', // 手机品牌

        "claimsId": "", // 理赔类型
        "phoneStatus": '', // 手机状态
        "malfunction": '', // 故障原因
        "damage": "", // 损坏情况 - 故障描述

        "name": "", // 姓名
        "telephone": "", // 电话
        "province": "", // 省份
        "city": "", // 市
        "county": "", // 区域
        "address": "", // 详细地址

        "damageFront": "", // 损坏部位正面
        "damageReverse": "", // 损坏部位反面
        "damageSide": "", // 损坏部位侧面
        "damageSideTwo": "", // 损坏部位侧面2

        "identityFront": "", // 身份证正面
        "identityHand": "", // 身份证手持
        "identityReverse": "", // 身份证反面

        "voucher": "", // 购买凭证
      },

      // 验证规则
      validateRoom: {
        damageFront: '请上传设备正面照片',
        damageReverse: '请上传设备反面照片',
        "damageSide": '请上传设备侧面照1',
        "identityHand": '请上传手持身份证',
        "identityFront": "请上传身份证正面",
        "identityReverse": "请上传身份证反面",
      },
      // AI结论
      aiVerdict: {
        name: '',
        idcard: '',
        period: '', // 有限期
      },

    }
  },
  mounted () {
    this.getStorage();
    if(this.type == 'edit'){
      this.getAidentity()
    }
  },
  methods: {
    goNext () {
      this.$router.go(-2)
    },
    /**
     * 获取身份AI识别存储
     */
    getAidentity(){
      const vm = this;
      let postData = {
        imei: this.$cookies.get('imei'),
        policyId: this.form.id,
      }
      this.$http
        .post('/apply/query/identity', postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.aiVerdict = {
              name: response.data.body.name,
              idcard: response.data.body.idcard,
              period: response.data.body.period,
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 身份证识别
     */
    idCardAI(url, direction){
      const vm = this;
      let postData = {
        filePath: url,
        imei: this.$cookies.get('imei'),
        policyId: this.form.id,
        direction: direction
      }
      // 清除原识别结果
      if(direction == 1){
        this.aiVerdict.name = '';
        this.aiVerdict.idcard = '';
      }else{
        this.aiVerdict.period = ''
      }
      // 删除图片不进行识别
      if(url == ''){
        return
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post('/apply/discern/identity', postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            if(direction == 1){
              vm.aiVerdict.name = response.data.body.name;
              vm.aiVerdict.idcard = response.data.body.idcard;
            }else{
              vm.aiVerdict.period = response.data.body.period;
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });

    },
    /**
     * 更新图片
     */
    upFile (url, key) {
      this.form[key] = url;
      if(key == 'identityFront'){
        // 身份证正面
        this.idCardAI(url, 1);
      }else if(key == 'identityReverse'){
        // 身份证反面
        this.idCardAI(url, 2);
      }
    },
    /**
     * 数据保存， 获取
     */
    getStorage () {
      let json = window.sessionStorage.getItem('formData');
      if(json){
        let form = JSON.parse(json);
        if(form.id == this.form.id){
          this.form = form;
        }else{
          this.$router.push('/search/imei?type=apply')
        }
      }else{
        this.$router.push('/search/imei?type=apply')
      }
    },
    setStorage () {
      window.sessionStorage.setItem('formData', JSON.stringify(this.form));
    },
    /**
     * 保存
     */
    saveData () {
      for(let key in this.validateRoom){
        if(! this.form[key]){
          this.$createToast({
            txt: this.validateRoom[key],
            type: 'warn'
          }).show()
          return false;
        }
      }
      const vm = this;
      let postData = {...this.form}
      let url;
      if(this.type == 'edit'){
        url = '/apply/update'
      }else{
        url = "/apply/save"
      }
      this.$http
        .post(url, postData)
        .then(function(response) {
          if (response.data.header.code == "A1000") {
            vm.show = true;
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.group-label{
  font-size: 24px;
  color: #999999;
  line-height: 37px;
  margin-bottom: 30px;
  margin-top: -10px;
  padding: 0 30px;
}
.flex-cont{
  display: flex;
  padding: 0 50px;
  justify-content: space-between;
}

.btns{
  width: 100%;
  padding: 40px 0 63px 0;
  z-index: 2;
  .btn{
    width: 540px;
    height: 80px;
    padding: 0;
    background: #208170;
    border-radius: 50px;
    margin: 0 auto;
  }
}
.modo{
  .modo-in{
    height: 518px;
  }
  .modo-title{
    height: 114px;
    span{
      color: #208170;
      font-weight: 700;
    }
  }
}

.box{
  margin: 30px 30px 30px 30px;
  padding: 0 30px;
  .form-group{
    border-top: 1px solid #DEDEDE;
    &:first-child{
      border: none;
    }
  }
}
.form-group{
  font-size: 28px;
  color: #333333;
  line-height: 30px;
  .label{
    padding: 30px 0;
  }
  &.line{
    display: flex;
    .label{
      width: 160px;
      &.mini{
        width: 100px;
      }
    }
    .cont{
      flex: 10;
      text-align: right;
      input{
        display: block;
        width: 100%;
        text-align: right;
        padding: 30px 0;
        height: 30px;
        border: none;
        outline: none;
        list-style: none;
      }
    }
    .cont-text{
      padding: 30px 0;
    }
  }
}
</style>